import React, { useState } from "react";
import DataContext from "./DataContext";

// global state management
export const DataProvider = ({ children }) => {
  const [quoteData, setQuoteData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [paymentData, setPaymentData] = useState(null);

  return (
    <DataContext.Provider
      value={{
        quoteData,
        setQuoteData,
        userData,
        setUserData,
        accessToken,
        setAccessToken,
        paymentData,
        setPaymentData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
