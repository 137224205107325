import React from "react";
import "./src/css/styles.css";
import "./dist/output.css";
// import Layout from "./src/components/Layout";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { DataProvider } from "./src/context/DataProvider";

export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        refetchOnWindowFocus: false,
        queryCache: new QueryCache({
          onError: (error, query) => {
            // 🎉 only show error toasts if we already have data in the cache
            // which indicates a failed background update
            if (query.state.data !== undefined) {
            }
          },
        }),
      },
    },
  });
  return (
    <DataProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {element}
      </QueryClientProvider>
    </DataProvider>
  );
};
